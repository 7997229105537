<template>
  <div class="l-select-item" :class="{ block: isMobile }">
    <div class="list">
      <p class="sub-title__top">365 올패스 Global 12개월</p>

      <ul id="selectboxGlobalPlanList">
        <li
          :class="[{ 'is-selected': copyChoicedOptionIdx === op.cp_idx }, `plan_${op.cp_idx}`]"
          @click="sendChoicedOptionId(op.cp_idx)"
          v-for="(op, index) in planGlobal"
          :key="index"
        >
          <span class="badge-red" v-if="index === 0">추천</span>

          <div class="textwrap left">
            <span class="tag t_type global">{{ tutorType(op.t_type) }}</span>
            <span class="tag puriod bonus">{{ monthCnt(op.month_cnt).bonus }}개월연장</span>
            <p>
              <span class="minutes">{{ op.minute }}분/</span>
              <span class="count__per__week" v-if="isMonthly === true">월{{ op.lesson_cnt / op.month_cnt }}회</span>
              <span class="count__per__week" v-if="isMonthly === false">주{{ op.lessonPerWeek }}회</span>
            </p>
            <div class="text">
              총
              <span class="lesson_cnt">{{ op.lesson_cnt }}회</span>
            </div>
          </div>
          <div class="textwrap right">
            <div class="line-through">
              정가
              <span class="origin__money">{{ priceComma(op.origin_money) }}원</span>
            </div>
            <span class="color-red">
              <span class="discount">{{ op.discount }}%</span>
            </span>
            <span class="text">
              <span class="money__per__month">{{ priceComma(op.money) }}원</span>
            </span>
          </div>
        </li>
      </ul>

      <p class="sub-title">365 올패스 Native 6개월</p>

      <ul id="selectboxNativePlanList">
        <li
          :class="[{ 'is-selected': copyChoicedOptionIdx === op.cp_idx }, `plan_${op.cp_idx}`]"
          @click="sendChoicedOptionId(op.cp_idx)"
          v-for="(op, index) in planNative"
          :key="index"
        >
          <div class="textwrap left">
            <span class="tag t_type native">{{ tutorType(op.t_type) }}</span>
            <span class="tag puriod bonus">{{ monthCnt(op.month_cnt).bonus }}개월연장</span>
            <p>
              <span class="minutes">{{ op.minute }}분/</span>

              <span class="count__per__week" v-if="isMonthly === true">월{{ op.lesson_cnt / op.month_cnt }}회</span>
              <span class="count__per__week" v-if="isMonthly === false">주{{ op.lessonPerWeek }}회</span>
            </p>
            <div class="text">
              총
              <span class="lesson_cnt">{{ op.lesson_cnt }}회</span>
            </div>
          </div>
          <div class="textwrap right">
            <div class="line-through">
              정가
              <span class="origin__money">{{ priceComma(op.origin_money) }}원</span>
            </div>
            <span class="color-red">
              <span class="discount">{{ op.discount }}%</span>
            </span>
            <span class="text">
              <span class="money__per__month">{{ priceComma(op.money) }}원</span>
            </span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
import { tracker } from '@/tools';
import inappTools from '@/common/inappTools';
import promotionMixins from '../../../common/mixins/promotion';

export default {
  name: 'PlanSelectCard',
  mixins: [promotionMixins],
  props: {
    planGlobal: {
      type: Array,
      required: true,
    },
    planNative: {
      type: Array,
      required: true,
    },
    choicedOptionIdx: {
      required: false,
    },
    isMobile: {
      type: Boolean,
      required: false,
    },
    choicedOptionData: {
      type: Object,
      required: false,
    },
    isMonthly: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      copyChoicedOptionIdx: this.choicedOptionIdx,
      isTabOpen: true,
    };
  },
  beforeUpdate() {
    this.copyChoicedOptionIdx = this.choicedOptionIdx;
  },
  methods: {
    // 수강권 번호 확인
    sendChoicedOptionId(option) {
      switch (option) {
        case 5923:
          if (inappTools.isInApp()) {
            tracker.recordUserAction('lastchance_payment_Global12_20min_total98');
          }
          break;
        case 5924:
          if (inappTools.isInApp()) {
            tracker.recordUserAction('lastchance_payment_Global12_20min_total140');
          }
          break;
        case 5921:
          if (inappTools.isInApp()) {
            tracker.recordUserAction('lastchance_payment_Global12_10min_total98');
          }
          break;
        case 5922:
          if (inappTools.isInApp()) {
            tracker.recordUserAction('lastchance_payment_Global12_10min_total140');
          }
          break;
        case 5919:
          if (inappTools.isInApp()) {
            tracker.recordUserAction('lastchance_payment_Native6_20min_total49');
          }
          break;
        case 5920:
          if (inappTools.isInApp()) {
            tracker.recordUserAction('lastchance_payment_Native6_20min_total70');
          }
          break;
        default:
          break;
      }

      this.copyChoicedOptionIdx = option;
      this.$emit('update', option);
    },
  },
  computed: {
    tutorType() {
      return (tType: string) => (tType === 'C' ? 'GLOBAL' : 'NATIVE');
    },
  },
};
</script>

<style scoped>
.l-select.is-on .l-select-item {
  display: block;
}
.l-select-item {
  display: none;
  position: absolute;
  bottom: 100%;
  width: 100%;
  left: -1px;
  border: 1px solid #ddd;
  background: #fff;
}
.l-select-item .title {
  position: relative;
  font-size: 14px;
  color: #333;
  line-height: 42px;
  padding-left: 20px;
  font-weight: 500;
}
.l-select-item .title:after {
  position: absolute;
  right: 15px;
  bottom: 15px;
  width: 7px;
  height: 7px;
  margin-left: 8px;
  border-top: 2px solid #ddd;
  border-left: 2px solid #ddd;
  transform: rotate(225deg);
  content: '';
}
@media (max-width: 1199px) {
  .l-select-item .title:after {
    right: 27px;
    width: 9px;
    height: 9px;
    bottom: auto;
    border-color: #333;
    transform: rotate(135deg);
  }
}

.l-select-item .color-red .discount {
  font-weight: 700;
  color: #ff1212;
}
.l-select-item .list {
  max-height: 216px;
  overflow: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.l-select-item .list::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera*/
}

@media (max-width: 1199px) {
  .l-select-item .list {
    max-height: none;
  }
}
.l-select-item .sub-title {
  padding: 31px 0 11px 22px;
  border-top: 10px solid #f6f6f6;
  line-height: 20px;
  font-size: 14px;
}

.l-select-item .sub-title__top {
  padding: 12px 0 12px 21px;
  line-height: 20px;
  font-size: 14px;
}

.l-select-item .sub-title:hover {
  background: #fff;
}
.l-select-item li {
  position: relative;
  width: 100%;
  display: table;
  padding: 20px 30px 23px;
  background: #fff;
  justify-content: space-between;
  box-sizing: border-box;
  line-height: 1;
  border: 1px solid hsla(0, 0%, 93.7%, 0.5);
  cursor: pointer;
}
.l-select-item li.is-selected {
  background: #f6f6f6;
}

.l-select-item li:hover {
  background: #f6f6f6;
}

.l-select-item li .textwrap {
  display: inline-block;
  padding: 0;
  width: 50%;
}
.l-select-item li .textwrap .line-through {
  font-size: 12px;
  text-decoration: line-through;
  color: #ccc;
}
.l-select-item li .textwrap.left .text {
  font-weight: 500;
}
.l-select-item li .textwrap.right {
  text-align: right;
  font-size: 16px;
}
.l-select-item li .textwrap.right .text {
  display: inline-block;
  margin-top: 4px;
  font-size: 16px;
  font-weight: 700;
  margin-left: 6px;
}
.l-select-item li .badge-red {
  position: absolute;
  top: 20px;
  right: 21px;
}

.badge-red {
  vertical-align: middle;
  display: inline-block;
  color: #fff;
  min-width: 33px;
  margin-bottom: 2px;
  margin-right: 9px;
  height: 17px;
  line-height: 16.8px;
  border-radius: 10vw;
  background: #ff1212;
  font-size: 10px;
  text-align: center;
  box-sizing: border-box;
}

.l-select-item li .tag {
  display: inline-block;
  color: #fff;
  line-height: 16px;
  font-size: 10px;
  padding: 0 6px;
  border-radius: 10vw;
  margin-right: 3px;
  text-transform: uppercase;
  font-weight: 600;
}
.l-select-item li .tag.puriod {
  background: #000;
}
.l-select-item li .tag.global {
  background: #5d04d9;
}
.l-select-item li .tag.native {
  background: #ff1212;
}
.l-select-item li p {
  margin-top: 14px;
  font-size: 14px;
  font-weight: 700;
}
.l-select-item li .text {
  margin-top: 7px;
  font-size: 14px;
}
</style>
