
export default {
  name: 'CountDown',
  data: () => ({
    remainingDate: new Date().getTime(),
    timer: '',
  }),
  props: {
    timestampEnd: {
      type: Number,
      default: new Date().getTime(),
    },
  },
  computed: {
    days1() {
      return this.splitNumber(this.dayCounter, 'first');
    },
    days2() {
      return this.splitNumber(this.dayCounter, 'second');
    },
    hours1() {
      return this.splitNumber(this.hourCounter, 'first');
    },
    hours2() {
      return this.splitNumber(this.hourCounter, 'second');
    },
    minutes1() {
      return this.splitNumber(this.minuteCounter, 'first');
    },
    minutes2() {
      return this.splitNumber(this.minuteCounter, 'second');
    },
    seconds1() {
      return this.splitNumber(this.secondCounter, 'first');
    },
    seconds2() {
      return this.splitNumber(this.secondCounter, 'second');
    },
    milliseconds1() {
      return this.splitNumber(this.millisecondCounter, 'first');
    },
    milliseconds2() {
      return this.splitNumber(this.millisecondCounter, 'second');
    },
  },
  methods: {
    // css 스타일 위해 각 자리수를 추출
    splitNumber(timeCounter, order: string) {
      const th = order === 'first' ? 0 : 1;
      let timeType = timeCounter(this.remainingDate);
      if (Object.prototype.toString.call(timeType) === 'String') {
        return timeType[th];
      }
      timeType = order === 'first' ? Math.floor(timeType / 10) : (timeType %= 10);
      return timeType;
    },

    updateTime(): void {
      this.remainingDate = this.timestampEnd - new Date().getTime();
    },

    dayCounter(timeStamp): string | number {
      const days = Math.floor(timeStamp / (1000 * 60 * 60 * 24));
      if (days < 0) return '00';
      if (days < 10) return `0${days}`;
      return days;
    },

    hourCounter(timeStamp): string | number {
      const hours = Math.floor((timeStamp % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      if (hours < 0) return '00';
      if (hours < 10) return `0${hours}`;
      return hours;
    },

    minuteCounter(timeStamp): string | number {
      const minutes = Math.floor((timeStamp % (1000 * 60 * 60)) / (1000 * 60));
      if (minutes < 0) return '00';
      if (minutes < 10) return `0${minutes}`;
      return minutes;
    },

    secondCounter(timeStamp): string | number {
      const seconds = Math.floor((timeStamp % (1000 * 60)) / 1000);
      if (seconds < 0) return '00';
      if (seconds < 10) return `0${seconds}`;
      return seconds;
    },

    millisecondCounter(timeStamp): string | number {
      const milliseconds = Math.floor((timeStamp % 1000) / 10);
      if (milliseconds < 0) return '00';
      if (milliseconds < 10) return `0${milliseconds}`;
      return milliseconds;
    },
  },
  created() {
    this.updateTime();
    this.timer = setInterval(this.updateTime, 1);
  },
  unmounted() {
    clearInterval(this.timer);
  },
};
