
import { tracker } from '@/tools';
import inappTools from '@/common/inappTools';
import promotionMixins from '../../../common/mixins/promotion';

export default {
  name: 'PlanSelectCard',
  mixins: [promotionMixins],
  props: {
    planGlobal: {
      type: Array,
      required: true,
    },
    planNative: {
      type: Array,
      required: true,
    },
    choicedOptionIdx: {
      required: false,
    },
    isMobile: {
      type: Boolean,
      required: false,
    },
    choicedOptionData: {
      type: Object,
      required: false,
    },
    isMonthly: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      copyChoicedOptionIdx: this.choicedOptionIdx,
      isTabOpen: true,
    };
  },
  beforeUpdate() {
    this.copyChoicedOptionIdx = this.choicedOptionIdx;
  },
  methods: {
    // 수강권 번호 확인
    sendChoicedOptionId(option) {
      switch (option) {
        case 5923:
          if (inappTools.isInApp()) {
            tracker.recordUserAction('lastchance_payment_Global12_20min_total98');
          }
          break;
        case 5924:
          if (inappTools.isInApp()) {
            tracker.recordUserAction('lastchance_payment_Global12_20min_total140');
          }
          break;
        case 5921:
          if (inappTools.isInApp()) {
            tracker.recordUserAction('lastchance_payment_Global12_10min_total98');
          }
          break;
        case 5922:
          if (inappTools.isInApp()) {
            tracker.recordUserAction('lastchance_payment_Global12_10min_total140');
          }
          break;
        case 5919:
          if (inappTools.isInApp()) {
            tracker.recordUserAction('lastchance_payment_Native6_20min_total49');
          }
          break;
        case 5920:
          if (inappTools.isInApp()) {
            tracker.recordUserAction('lastchance_payment_Native6_20min_total70');
          }
          break;
        default:
          break;
      }

      this.copyChoicedOptionIdx = option;
      this.$emit('update', option);
    },
  },
  computed: {
    tutorType() {
      return (tType: string) => (tType === 'C' ? 'GLOBAL' : 'NATIVE');
    },
  },
};
