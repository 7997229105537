import { render } from "./Pr0301.vue?vue&type=template&id=202f8bc9&scoped=true"
import script from "./Pr0301.vue?vue&type=script&lang=ts"
export * from "./Pr0301.vue?vue&type=script&lang=ts"

import "../../assets/promotions.css?vue&type=style&index=0&id=202f8bc9&scoped=true&lang=css"
import "../../assets/promotionsA.css?vue&type=style&index=1&id=202f8bc9&scoped=true&lang=css"
import "./Pr0301.vue?vue&type=style&index=2&id=202f8bc9&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-202f8bc9"
/* hot reload */
if (module.hot) {
  script.__hmrId = "202f8bc9"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('202f8bc9', script)) {
    api.reload('202f8bc9', script)
  }
  
  module.hot.accept("./Pr0301.vue?vue&type=template&id=202f8bc9&scoped=true", () => {
    api.rerender('202f8bc9', render)
  })

}

script.__file = "src/page/promotion/Pr0301.vue"

export default script