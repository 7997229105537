import { render } from "./CountDown.vue?vue&type=template&id=59a97668"
import script from "./CountDown.vue?vue&type=script&lang=ts"
export * from "./CountDown.vue?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "59a97668"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('59a97668', script)) {
    api.reload('59a97668', script)
  }
  
  module.hot.accept("./CountDown.vue?vue&type=template&id=59a97668", () => {
    api.rerender('59a97668', render)
  })

}

script.__file = "src/page/promotion/component/CountDown.vue"

export default script