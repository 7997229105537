<template>
  <AppNav v-if="appView">가격 인상 전 마지막 혜택!</AppNav>

  <div class="nov-third-allpage" :style="{ 'padding-top': appPadding }">
    <div
      v-if="isSampleClicked"
      class="common-modal common-modal--dimmed"
      id="modalCertificateClose"
      style="display: block"
    >
      <div class="common-modal__modal modal__certificate">
        <div class="common-modal__content">
          <h1 class="heading">튜터링 증빙서류 샘플</h1>
          <p class="description">
            수강증, 출석확인증, 영수증 상시 발급 가능
            <br />
            연 수강권의 경우 매월 수강권 발급이 가능합니다.
          </p>
          <ul class="list">
            <li class="list__item">
              <img width="270" src="//res.tutoring.co.kr/res/images/evt/com/receipt_img01.png" alt="" />
              - 수강증 증빙서류 샘플 -
            </li>
            <li class="list__item">
              <img width="270" src="//res.tutoring.co.kr/res/images/evt/com/receipt_img02.png" alt="" />
              - 출석 확인증 증빙서류 샘플 -
            </li>
            <li class="list__item">
              <img width="270" src="//res.tutoring.co.kr/res/images/evt/com/receipt_img03.png" alt="" />
              - 영수증 증빙서류 샘플 -
            </li>
          </ul>
        </div>
        <button type="button" class="modal__certificate__close" @click="openSample">닫기</button>
      </div>
    </div>

    <div class="early-bird-page atype-page" id="tutoringContent">
      <!-- app nav -->

      <!-- swiper -->
      <div class="slide-01 slide">
        <swiper
          :loop="true"
          :slidesPerView="1"
          :speed="500"
          :autoplay="{ dalay: '200', disableOnInteraction: false }"
          :pagination="{ clickable: true }"
          :navigation="{
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          }"
        >
          <swiper-slide>
            <div class="slide__image slide__image--1"></div>
          </swiper-slide>
          <swiper-slide>
            <div class="slide__image slide__image--2"></div>
          </swiper-slide>
          <swiper-slide>
            <div class="slide__image slide__image--3"></div>
          </swiper-slide>
          <swiper-slide>
            <div class="slide__image slide__image--4"></div>
          </swiper-slide>

          <div class="swiper-button-next"></div>
          <div class="swiper-button-prev"></div>
        </swiper>
      </div>

      <!-- 구매 영역 -->
      <div class="atype">
        <div class="textarea">
          <div class="textarea__title">
            <div class="title">
              아직 늦지 않았다!
              <br />
              영어 공부 시작할 마지막 기회
            </div>
            <div class="date">2022.03.02 - 2022.03.20</div>
          </div>
          <!-- 선택 -->
          <div class="textarea__option">
            <div class="title">365 올패스 옵션 선택</div>
            <div
              class="pc-layer l-select"
              :class="{ 'is-on': isPcTabOpen }"
              id="topSelectPlanOptionPC"
              @click="clickTab('pcTab')"
            >
              <span class="selected__plan__title">
                <span v-if="choicedOptionData.cp_idx === promotionData.plans[0].cp_idx" class="badge-red">추천</span>
                {{
                  `${choicedOptionData.tutorTypeLower} ${monthCnt(choicedOptionData.month_cnt).originMonth}개월 ${
                    choicedOptionData.minute
                  }분 월${choicedOptionData.lesson_cnt / choicedOptionData.month_cnt}회`
                }}
              </span>

              <!-- 데스크탑 셀렉트 -->
              <div v-if="isPcTabOpen" class="dim__vue" v-click-outside="closeTab">
                <PlanSelectCard
                  @update="updateOptionIdx"
                  :choicedOptionIdx="choicedOptionIdx"
                  :planGlobal="planGlobal"
                  :planNative="planNative"
                  :choicedOptionData="choicedOptionData"
                  :isMonthly="true"
                ></PlanSelectCard>
              </div>
            </div>

            <!-- 모바일 셀렉트 -->
            <span class="tamo-layer l-select no-select" @click="clickTab('mobileTab')" id="topSelectPlanOptionTAMO">
              <span v-if="choicedOptionData.cp_idx === promotionData.plans[0].cp_idx" class="badge-red">추천</span>
              <span class="bold">
                {{
                  `${choicedOptionData.tutorTypeLower} ${monthCnt(choicedOptionData.month_cnt).originMonth}개월 ${
                    choicedOptionData.minute
                  }분 월${choicedOptionData.lesson_cnt / choicedOptionData.month_cnt}회`
                }}
              </span>
            </span>

            <div @click="closeTab" class="dim"></div>
          </div>

          <!-- 데스크탑 수강권 상세 설명 -->
          <div id="selectedPlanData">
            <div class="textarea__info">
              <div class="title">옵션 상세</div>
              <ul>
                <li>
                  <strong>정상가</strong>
                  <span class="origin__money">
                    {{ priceComma(choicedOptionData.origin_money) }}
                  </span>
                  원
                </li>
                <li>
                  <strong>할인 혜택</strong>
                  <span class="discount">{{ choicedOptionData.price }}{{ choicedOptionData.discount }}%</span>
                  + 최대
                  <span class="coupon_discount">{{ choicedOptionData.choicedOptionCoupon }} 쿠폰</span>

                  <span
                    v-scroll-to="{
                      el: '#coupons',
                      duration: 500,
                      easing: 'linear',
                      offset: -exceptBnrScroll,
                      x: false,
                      y: true,
                    }"
                    class="download"
                  >
                    쿠폰 다운로드
                  </span>
                </li>
                <li>
                  <strong>수업 시간</strong>
                  <span class="minutes">{{ choicedOptionData.minute }}분</span>
                  /
                  <span class="count__per__week">
                    주{{ choicedOptionData.lessonPerWeek }}회(월
                    {{ choicedOptionData.lesson_cnt / choicedOptionData.month_cnt }}회)
                  </span>
                  / 총
                  <span class="lesson_cnt">{{ choicedOptionData.lesson_cnt }}회</span>
                </li>
                <li>
                  <strong>튜터 타입</strong>
                  <span class="t_type">{{ choicedOptionData.tutorType }}</span>
                  <!-- 호버 효과 -->
                  <span class="q" :class="{ 'is-native': choicedOptionData.tutorType === 'NATIVE' }" id="q">?</span>
                </li>
                <li>
                  <strong>추가 혜택</strong>

                  <span class="bonus_month">{{ monthCnt(choicedOptionData.month_cnt).bonus }}개월 연장</span>
                </li>
              </ul>
            </div>
          </div>

          <!-- 판매가 정보 부분(데스크탑, 모바일 공통) -->
          <div class="textarea__price">
            <ul>
              <li class="sell">
                <strong class="title">할인 판매가</strong>
                <div class="price">
                  <span class="discount">{{ choicedOptionData.discount }}%</span>
                  <strong class="money">{{ priceComma(choicedOptionData.money) }}원</strong>
                </div>
                <span class="line-through">
                  <span class="pc-layer">정가</span>
                  <span class="origin__money">{{ priceComma(choicedOptionData.origin_money) }}원</span>
                </span>
              </li>
              <li class="sale tamo-layer">
                <strong class="title">최대 할인가</strong>
                <span class="mini-desc">
                  쿠폰 적용,
                  {{ monthCnt(choicedOptionData.month_cnt).originMonth }}개월 무이자 할부시
                </span>
              </li>
              <li class="coupon tamo-layer">
                <span class="coupon title">
                  <span class="discount">{{ choicedOptionData.discount }}%</span>
                  &nbsp;+&nbsp;
                  <span class="coupon_discount">{{ choicedOptionData.choicedOptionCoupon }}</span>
                  &nbsp;쿠폰
                </span>
                <strong class="moneyPerMonthAdaptedCoupon">
                  월 {{ priceComma(choicedOptionData.monthlyPrice) }}원
                </strong>
              </li>
              <li class="sale-coupon pc">
                <span>
                  <strong class="title">쿠폰 최대 적용가</strong>
                  <span class="coupon">
                    <span class="discount">{{ choicedOptionData.discount }}%</span>
                    &nbsp;+&nbsp;
                    <span class="coupon_discount">
                      {{ choicedOptionData.choicedOptionCoupon }}
                    </span>
                    &nbsp;쿠폰
                  </span>
                </span>
                <span class="price">
                  <strong class="mini-desc">
                    쿠폰 적용,
                    {{ monthCnt(choicedOptionData.month_cnt).originMonth }}개월 무이자 할부시
                  </strong>
                  <strong class="moneyPerMonthAdaptedCoupon price">
                    월 {{ priceComma(choicedOptionData.monthlyPrice) }}원
                  </strong>
                </span>
              </li>
            </ul>
          </div>
          <div style="cursor: pointer" class="btn goToPay" id="purchaseTracker" @click="purchasePlan()">바로 구매</div>
        </div>
      </div>
    </div>

    <!-- 쿠폰 -->
    <section class="couponwrap" id="coupons">
      <div class="top-desc">
        즉시 사용 가능 쿠폰!
        <br />
        지금 다운로드 하세요
      </div>
      <!-- 다운로드 받은 쿠폰인 경우, is-download -->
      <div
        v-for="(coupon, index) in promotionData.coupons"
        :key="index"
        @click="postCouponDownload(coupon.dcIdx, index)"
        :class="[
          { 'coupon5000 coupon coupon-dwn__for__login': coupon.discountMoney === 5000 },
          { 'coupon20000 coupon coupon-dwn__for__login': coupon.discountMoney === 20000 },
          { 'coupon30000 coupon coupon-dwn__for__login': coupon.discountMoney === 30000 },
          { 'is-download': coupon.hasCoupon || hasCouponIdxArray(coupon.dcIdx) },
        ]"
      >
        <span class="coupon__button"></span>
      </div>

      <div class="bottom-desc">
        ※ 쿠폰 중복 사용 불가
        <br />
        ※ 쿠폰은 다운로드를 해야만 사용 가능
        <br />
        ※ 해당 이벤트 수강권에만 사용 가능
      </div>
    </section>

    <section class="nov-third-allpage__price">
      <div class="nov-third-allpage__price-img"></div>
    </section>

    <section class="nov-third-allpage__tudal">
      <p class="nov-third-allpage__tudal-text">
        아직 늦지 않았다!
        <br />
        미뤄둔 영어 공부
        <br />
        시작할 마지막 기회!
      </p>
      <div class="nov-third-allpage__tudal-img"></div>
    </section>

    <section class="grade-desc nov-third-allpage__grade_desc">
      <div class="nov-third-allpage__grade_desc__img"></div>
    </section>

    <CountDown :timestampEnd="timestampEnd">
      <template v-slot:default="slotProps">
        <section class="count-down is-julysummer" :class="{ 'is-date-end': isDueDateEnd(timestampFinal) }">
          <div class="title-img"></div>
          <div class="date-end"></div>
          <div class="day">
            <span class="d-img"></span>

            <span class="count-down__number">
              <span id="days1">{{ slotProps.days1 }}</span>
            </span>
            <span class="count-down__number">
              <span id="days2">{{ slotProps.days2 }}</span>
            </span>
          </div>

          <div class="timer">
            <span class="count-down__number">
              <span id="hours1">{{ slotProps.hours1 }}</span>
            </span>
            <span class="count-down__number">
              <span id="hours2">{{ slotProps.hours2 }}</span>
            </span>

            <span class="dot"></span>

            <span class="count-down__number">
              <span id="minutes1">{{ slotProps.minutes1 }}</span>
            </span>
            <span class="count-down__number">
              <span id="minutes2">{{ slotProps.minutes2 }}</span>
            </span>

            <span class="dot"></span>
            <span class="count-down__number">
              <span id="seconds1">{{ slotProps.seconds1 }}</span>
            </span>
            <span class="count-down__number">
              <span id="seconds2">{{ slotProps.seconds2 }}</span>
            </span>

            <span class="dot"></span>

            <span class="count-down__number">
              <span id="milliseconds1">{{ slotProps.milliseconds1 }}</span>
            </span>
            <span class="count-down__number">
              <span id="milliseconds2">{{ slotProps.milliseconds2 }}</span>
            </span>
          </div>
        </section>
      </template>
    </CountDown>

    <section class="atype-imgwrap">
      <p>
        튜터 타입이
        <br />
        궁금하신가요?
      </p>
      <div class="img"></div>
    </section>

    <section class="happy-allpass">
      <h2 class="happy-allpass__title">
        언택트 시대
        <br />
        튜터링으로 영어 해결!
      </h2>
      <ul class="diagram">
        <li class="diagram__item diagram__item--speaking">
          <strong class="diagram__point">Speaking</strong>
          24시간 실시간
          <br />
          라이브 1:1 회화
        </li>
        <li class="diagram__item diagram__item--writing">
          <strong class="diagram__point">Writing</strong>
          실시간
          <br />
          영작 첨삭
        </li>
        <li class="diagram__item diagram__item--listening">
          <strong class="diagram__point">LISTENING</strong>
          24시간 무제한
          <br />
          AI 튜터와 예습
        </li>
      </ul>
      <p class="happy-allpass__desc">
        원어민 회화
        <i class="plus">+</i>
        AI 학습
        <i class="plus">+</i>
        첨삭
      </p>
    </section>
    <section class="explan">
      <div class="img"></div>
    </section>
    <section class="purple-mobile">
      <div class="img">
        <p>
          일상 속
          <br />
          완벽한 영어 환경
        </p>
      </div>
    </section>
    <section class="cards">
      <div class="img"></div>
    </section>
    <section class="pride">
      <p class="title">
        <strong class="title__no1">4년 연속 1위</strong>
        소비자가 직접 뽑은 1:1 영어회화 부문
      </p>
      <img class="img" src="//res.tutoring.co.kr/res/images/evt/com/award_set.png" alt="2022 퍼스트 브랜드 대상" />
      <ul class="pride__img">
        <li></li>
        <li></li>
        <li></li>
      </ul>
    </section>
    <section class="ontact-review">
      <h2 class="heading">
        직접 이용하고 계신
        <br />
        회원님들의 솔직한 후기
      </h2>
      <div class="review__item">
        <p class="description">
          시간, 장소의 제약 없이 내가 원할 때 영어 공부가 가능해서 좋아요. 실제 업무에서 쓰고 싶은 내용을 수업에서
          튜터와 이야기 합니다. 어색한 부분이나 잘못된 문장을 교정해 주실때 도움이 많이 돼요.
        </p>
        <span class="name">미정님</span>
      </div>
      <div class="review__item">
        <p class="description">
          정해진 수업 시간은 나중에 그 시간이 압박으로 다가오는데, 튜터링은 아무 때나 내가 원하는 시간에 수업을 할 수
          있고 또 토픽카드로 셀프 스터디를 할 수 있어서 좋아요.
        </p>
        <span class="name">써니님</span>
      </div>
      <div class="review__item">
        <p class="description">
          내가 원하는 시간에 수업이 가능하고, 원하는 시간만큼 연장 수업이 가능한 부분이 편리해요. 또 수업이 끝나면
          피드백을 바로 받을 수 있고, 들으며 놓쳤던 부분을 이해할 수 있어 복습에 도움이 됩니다.
        </p>
        <span class="name">수진님</span>
      </div>
    </section>

    <section class="focus-certificate">
      <div class="promotion-certificate">
        <h2 class="heading">수강증 상시 발급 가능</h2>
        <p class="description">수강증, 출석확인증, 영수증 상시 발급 가능</p>
        <div class="certificate__tip">
          직장인들을 위한 SPEC UP!
          <br />
          사내 교육지원비로 알뜰하게!
        </div>
        <button @click="openSample" type="button" class="button modal__certificate__open">
          증빙서류 샘플 상세보기
        </button>
      </div>
      <div class="promotion-guide">
        <h2 class="heading">증빙 서류 발급 안내</h2>
        <ul class="guide__list">
          <li class="list__item">
            수강증, 출석 확인증, 영수증은 튜터링 앱 메인 화면 우측 하단 [마이튜터링] &gt; [고객센터] &gt; [증빙 서류
            발급 메뉴]에서 신청 가능합니다.
          </li>
          <li class="list__item">정해진 학습 기간 내에 수업을 진행해야만 출석으로 인정합니다.</li>
          <li class="list__item">증빙서류의 경우 내용(수강권명, 금액)등은 임의 수정이 불가합니다.</li>
          <li class="list__item">
            발급 신청 시 입력한 이메일 주소로 자동 발급됩니다.(기타 문의사항은 [고객센터 &gt; 1:1 채팅 문의]로 연락
            주시기 바랍니다.)
          </li>
        </ul>
      </div>
    </section>

    <!-- 하단 유의 사항  -->
    <section class="promotion-notice">
      <div class="notice__item">
        <h2 class="heading">
          <button type="button" class="heading__button">튜터링 영어 유의사항 안내</button>
        </h2>
        <div class="inner" v-for="(item, index) in promotionData.planGuide" :key="index">
          <h2 class="notice__title">{{ item.title }}</h2>
          <ul class="notice__list">
            <li v-for="(text, i) in sortNoticeText[index]" :key="i" class="list__item">
              <p v-html="text"></p>
            </li>
          </ul>
        </div>
      </div>
    </section>

    <!-- {/*
          is-info : A타입
          is-simple : B타입
        */} -->

    <section class="challenge-cta is-info" :class="{ 'ani-bottom-up': bottomSheetUp }" id="challengeCta">
      <!-- l-select 에 is-on 넣을때 얘도 같이 is-on 넣어주세요  -->
      <div class="dim"></div>
      <div class="challenge-cta__wrap">
        <div class="wrapper">
          <!-- 20210727 수정 - app 과 UI 통일  -->
          <button
            v-if="isMobileBottomSheetOpen"
            @click="purchasePlan()"
            type="button"
            id="purchaseTracker"
            class="bottom_tab"
          >
            바로 구매
          </button>

          <div class="textwrap" v-if="!isMobileBottomSheetOpen">
            <CountDown :timestampEnd="timestampEnd">
              <template v-slot:default="slotProps">
                <div class="remain">
                  <h2 class="heading">남은 시간</h2>
                  <span class="date" v-if="!isDueDateEnd(timestampFinal)">
                    D -&nbsp;
                    <span id="day1">{{ slotProps.days1 === 0 ? '' : slotProps.days1 }}</span>
                    <span id="day2">{{ slotProps.days2 }}</span>
                  </span>
                  <span class="date" v-if="isDueDateEnd(timestampFinal)">
                    <span id="day1">오늘 마감</span>
                  </span>
                  <div class="time">
                    <span>
                      <span id="hour1">{{ slotProps.hours1 }}</span>
                      <span id="hour2">{{ slotProps.hours2 }}</span>
                    </span>
                    :
                    <span>
                      <span id="minute1">{{ slotProps.minutes1 }}</span>
                      <span id="minute2">{{ slotProps.minutes2 }}</span>
                    </span>
                    :
                    <span>
                      <span id="second1">{{ slotProps.seconds1 }}</span>
                      <span id="second2">{{ slotProps.seconds2 }}</span>
                    </span>
                    :
                    <span>
                      <span id="milliseconds1">{{ slotProps.milliseconds1 }}</span>
                      <span id="milliseconds2">{{ slotProps.milliseconds2 }}</span>
                    </span>
                  </div>
                </div>
              </template>
            </CountDown>

            <div class="selected-option">
              <h2 class="heading">선택옵션</h2>
              <!-- {/* is-on 클래스 넣으면 내부의 l-selcted-item 이 보여집니다. */} -->
              <div
                class="l-select"
                :class="{ 'is-on': isBottomTabOpen }"
                @click="clickTab('mobileBottom')"
                id="ctaPlanSelect"
              >
                <p class="selected-plan__title">
                  <span v-if="choicedOptionData.cp_idx === promotionData.plans[0].cp_idx" class="badge-red">추천</span>

                  <strong>
                    {{
                      `365 올패스 ${choicedOptionData.tutorTypeLower} ${
                        monthCnt(choicedOptionData.month_cnt).originMonth
                      }+${monthCnt(choicedOptionData.month_cnt).bonus}개월  ${choicedOptionData.minute}분 / 월 ${
                        choicedOptionData.lesson_cnt / choicedOptionData.month_cnt
                      }회`
                    }}
                  </strong>

                  총 {{ choicedOptionData.lesson_cnt }}회
                </p>
                <!-- 2. 피씨 버전 바텀 셀렉트 창 -->
                <div v-if="isBottomTabOpen" class="dim__Vue" v-click-outside="closeTab">
                  <PlanSelectCard
                    @update="updateOptionIdx"
                    :choicedOptionIdx="choicedOptionIdx"
                    :planGlobal="planGlobal"
                    :planNative="planNative"
                    :choicedOptionData="choicedOptionData"
                    :isMonthly="true"
                  ></PlanSelectCard>
                </div>
              </div>
            </div>
          </div>

          <div class="btnwrap" v-if="!isMobileBottomSheetOpen">
            <div class="price">
              <span class="money__month">
                쿠폰 적용,
                {{ monthCnt(choicedOptionData.month_cnt).originMonth }}개월 무이자 할부시
              </span>
              <div class="money__per__month">월 {{ priceComma(choicedOptionData.monthlyPrice) }}원</div>
            </div>

            <!-- pc 버전 -->
            <button type="button" @click="purchasePlan()" class="cta__button cta__button pc-layer pc goToPay">
              바로 구매
            </button>
            <button
              type="button"
              class="cta__mobile cta__button tamo-layer tablet-mobile"
              data-status="N"
              @click="clickBottomSheetTab()"
            >
              구매 하기
            </button>
          </div>
        </div>
      </div>

      <!-- 모바일 크기일 때 구매 모달창 -->
      <!-- {/* is-show 넣으면 올라옵니다. */} -->
      <article class="sheet" :class="{ 'is-show': isMobileBottomSheetOpen }" id="bottomUpSheet">
        <div class="sheet__wrap">
          <div class="info">
            <div class="btn" @click="btnTracker"></div>
            <strong class="option">수강권 선택 옵션</strong>
            <div class="change-btn no-select" @click="clickTab('mobileTabChange')">
              <span class="text">변경</span>
            </div>
            <div class="textwrap">
              <span v-if="choicedOptionData.cp_idx === promotionData.plans[0].cp_idx" class="discount">추천</span>
              <span class="name bottom-up__title">
                {{
                  `365 올패스 ${choicedOptionData.tutorTypeLower}  ${
                    monthCnt(choicedOptionData.month_cnt).originMonth
                  }+${monthCnt(choicedOptionData.month_cnt).bonus}개월`
                }}
              </span>
            </div>
            <p>
              <strong>
                <span class="minutes">{{ choicedOptionData.minute }}분</span>
                /
                <span class="count__per__week">
                  월{{ choicedOptionData.lesson_cnt / choicedOptionData.month_cnt }}회
                </span>
              </strong>
              <span class="all">
                <span class="lesson_cnt">총{{ choicedOptionData.lesson_cnt }}회</span>
              </span>
            </p>
          </div>
          <div class="price-wrap">
            <strong>최대 할인가</strong>
            <span class="price-area" :style="{ 'margin-right': isNotAppMargin }">
              <span class="price">
                <div class="price-comment">
                  쿠폰 적용, {{ monthCnt(choicedOptionData.month_cnt).originMonth }}개월 무이자 할부 시
                </div>
                <div class="moneyPerMonthAdaptedCoupon">
                  월 &nbsp;{{ priceComma(choicedOptionData.monthlyPrice) }}원
                </div>
              </span>
            </span>
          </div>
        </div>
        <div class="dim" @click="closeTab"></div>
      </article>
    </section>

    <!-- 수강권 선택 팝업 -->
    <div class="popup_layer coupon-select" :class="{ block: isMobileTabOpen }">
      <div class="popup_layer-wrap">
        <div class="popup_layer-content">
          <div class="popup-title">
            수강권 선택하기
            <span class="close" @click="closeTracker"></span>
          </div>
          <!-- 3. 모바일 버전 셀렉트 창 -->

          <PlanSelectCard
            @update="updateOptionIdx"
            :isMobile="isMobileTabOpen"
            :planGlobal="planGlobal"
            :planNative="planNative"
            :choicedOptionIdx="choicedOptionIdx"
            :isMonthly="true"
          ></PlanSelectCard>
        </div>
      </div>
      <div @click="closeTab" class="dim"></div>
    </div>

    <PopupToast :isPopup="isPopup"><p v-html="cuponPopupMessage"></p></PopupToast>
  </div>
</template>

<script lang="ts">
import { Swiper, SwiperSlide } from 'swiper/vue';
import SwiperCore, { Navigation, Pagination, EffectFade, Autoplay } from 'swiper';
import { AxiosResponse } from 'axios';
import vClickOutside from 'click-outside-vue3';

import { storage, native, tracker } from '@/tools';
import api from '@/common/api';
import inappTools from '@/common/inappTools';
import tools from '@/common/tools';

import CountDown from './component/CountDown.vue';
import PlanSelectCard from './component/PlanSelectCard.vue';
import promotionMixins from '../../common/mixins/promotion';
import PopupToast from '../../components/PopupToast.vue';
import AppNav from '../../components/AppNav.vue';

import 'swiper/swiper-bundle.css';

SwiperCore.use([Navigation, Pagination, EffectFade, Autoplay]);

export default {
  name: 'Pr0301',
  components: {
    Swiper,
    SwiperSlide,
    PlanSelectCard,
    CountDown,
    PopupToast,
    AppNav,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  mixins: [promotionMixins],
  data() {
    return {
      isPopup: false,
      cuponPopupMessage: '쿠폰 다운로드 완료!',

      closedPromotion: false,

      planWithPrice: [{}],
      promotionData: { plans: [{ cp_idx: 0 }] },

      planGlobal: [{}],
      planNative: [{}],

      isPcTabOpen: false,
      isMobileTabOpen: false,
      isBottomTabOpen: false,
      isSampleClicked: false,

      // 사용자가 선택한 플랜의 idx
      choicedOptionIdx: 5715 as Number,

      // 사용자가 선택한 플랜 데이터 저장 _ 옵션 상세 부분 및 구매 이동에서 사용
      choicedOptionData: {
        origin_money: '',
        price: 0,
        discount: 0,
        minute: 0,
        lessonPerWeek: 0,
        lesson_cnt: 0,
        money: '',
        moneyPerMonth: 0,
        t_type: '',
        month_cnt: 0,
        choicedOptionCoupon: '',
        monthlyPrice: 0,
        tutorTypeLower: '',
      } as {
        // eslint-disable-next-line camelcase
        origin_money: string;
        price: number;
        discount: number;
        minute: number;
        lessonPerWeek: number;
        // eslint-disable-next-line camelcase
        lesson_cnt: number;
        money: string;
        moneyPerMonth: number;
        // eslint-disable-next-line camelcase
        t_type: string;
        // eslint-disable-next-line camelcase
        month_cnt: number;
        choicedOptionCoupon: string;
        monthlyPrice: number;
        tutorTypeLower: string;
      },

      // TODO : 프로모션 끝나는 기간 서버에서 받아와서 넣어야 함
      timestampEnd: new Date('20 Mar 2022 23:59:59').getTime(),
      timestampFinal: new Date('20 Mar 2022 00:00:00').getTime(),

      hasCouponIdx: [],

      bottomSheetUp: false,

      isMobileBottomSheetOpen: false,
    };
  },

  created() {
    this.getPromotionData();
  },
  // 스크롤 이벤트
  mounted() {
    this.onBottomSheetOn();
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.onBottomSheetOn);
  },

  methods: {
    clickTab(tabType: string): void {
      switch (tabType) {
        case 'pcTab':
          this.isPcTabOpen = !this.isPcTabOpen;
          break;

        case 'mobileTab':
          this.isMobileTabOpen = !this.isMobileTabOpen;
          if (inappTools.isInApp()) {
            tracker.recordUserAction('lastchance_payment_main');
          }
          break;
        case 'mobileTabChange':
          this.isMobileTabOpen = !this.isMobileTabOpen;
          if (inappTools.isInApp()) {
            tracker.recordUserAction('lastchance_change');
          }
          break;
        case 'mobileBottom':
          this.isBottomTabOpen = !this.isBottomTabOpen;
          break;

        default:
          break;
      }
    },
    closeTab(): void {
      this.isPcTabOpen = false;
      this.isMobileTabOpen = false;
      this.isBottomTabOpen = false;
      this.isMobileBottomSheetOpen = false;
    },
    closeTracker() {
      this.isMobileTabOpen = false;
      if (inappTools.isInApp()) {
        tracker.recordUserAction('lastchance_payment_close');
      }
    },
    btnTracker() {
      if (inappTools.isInApp()) {
        tracker.recordUserAction('lastchance_footer_close');
      }
      this.isMobileBottomSheetOpen = false;
    },

    openSample(): void {
      this.isSampleClicked = !this.isSampleClicked;
    },

    clickPopupButton() {
      this.isPopup = true;
      setTimeout(() => {
        this.isPopup = false;
      }, 2000);
    },
    async getPromotionData(): Promise<any> {
      // 로그인 되어 있을 경우 localStorage의 Key 사용
      // 로그인 되어 있지 않을 경우 하드코딩 된 Key 사용
      this.promotionData = await api
        .get('/app/etc/promotion', {
          channel: 8,
          promotionCode: 'MonthlyPro_202203',
        })
        .then((response: AxiosResponse) => response.data);

      this.choicedOptionIdx = this.promotionData.plans[0].cp_idx;

      this.choicedOptionData = {
        ...this.promotionData.plans[0],

        choicedOptionCoupon:
          this.promotionData.couponPriceInfo[0].discountMoney > 10000
            ? `${this.promotionData.couponPriceInfo[0].discountMoney.toString().charAt(0)}만원`
            : `${this.promotionData.couponPriceInfo[0].discountMoney.toString().charAt(0)}천원`,

        monthlyPrice: this.promotionData.couponPriceInfo[0].moneyPerMonthAdaptedCoupon,

        tutorType: this.promotionData.plans[0].t_type === 'C' ? 'GLOBAL' : 'NATIVE',
        tutorTypeLower: this.promotionData.plans[0].t_type === 'C' ? 'Global' : 'Native',
      };

      this.planWithPrice = this.promotionData.plans.map((v, i) => ({
        ...v,
        couponPrice: this.promotionData.couponPriceInfo[i].discountMoney / 10000,
      }));

      this.planGlobal = this.planWithPrice.filter((data) => data.t_type === 'C');
      this.planNative = this.planWithPrice.filter((data) => data.t_type === 'B');
    },

    async postCouponDownload(dcIdx: number, index: number): Promise<any> {
      if (inappTools.isInApp()) {
        tracker.recordUserAction(`lastchance_Coupon_${index + 1}`);
      }

      const userKey = localStorage.getItem('key');

      if (!userKey) {
        window.location.href = `https://${document.location.host}/home/login?goto=${document.location.pathname}`;
      } else {
        await api
          .post(`/app/users/coupon/discount/${dcIdx}`, {})
          .then((response) => {
            if (response.result === 2000) {
              this.cuponPopupMessage = '쿠폰 다운로드 완료!';
              this.hasCouponIdx.push(dcIdx);
              this.clickPopupButton();
            } else if (response.result === 1000) {
              this.cuponPopupMessage = response.message.replace(/\n|n/gi, '<br />');
              this.clickPopupButton();
            }
          })
          .catch((response) => {
            console.log(response);
          });
      }
    },
    clickBottomSheetTab() {
      if (inappTools.isInApp()) {
        tracker.recordUserAction('lastchance_footer');
      }
      if (this.isMobileBottomSheetOpen) {
        this.purchasePlan();
      }
      this.isMobileBottomSheetOpen = !this.isMobileBottomSheetOpen;
    },

    updateOptionIdx(Id) {
      this.choicedOptionIdx = Id;
      this.isMobileTabOpen = false;
    },

    pushToLogin() {
      if (!tools.isLoggedIn()) {
        window.location.href = '/home/login';
      }
    },

    purchasePlan() {
      const selectedPlan = this.promotionData.plans.filter((plan) => plan.cp_idx === this.choicedOptionIdx)[0];
      storage.session.set('goodsForOrder', selectedPlan);
      if (inappTools.isInApp()) {
        tracker.recordUserAction('lastchance_footer_payment');
        native.close();
      } else {
        this.pushToLogin();
        // eslint-disable-next-line no-useless-concat
        const url = `https://${document.location.host}/home/order?&ptype=Kr`;
        window.location.href = url;
      }
    },

    onBottomSheetOn() {
      window.addEventListener('scroll', () => {
        if (window.pageYOffset > 1926) {
          this.bottomSheetUp = true;
        } else {
          this.bottomSheetUp = false;
        }
      });
    },
  },

  computed: {
    hasCouponIdxArray() {
      return (dcIdx) => this.hasCouponIdx.includes(dcIdx);
    },

    sortNoticeText() {
      return this.promotionData.planGuide.map((data) => data.contents.map((e) => e.text.replace(/\n|n/gi, '<br />')));
    },
    appView() {
      return inappTools.isInApp();
    },
    isNotAppMargin() {
      if (!inappTools.isInApp()) {
        return '60px';
      }
      return '0px';
    },
    appPadding() {
      if (inappTools.isInApp()) {
        return '45px';
      }
      return '0px';
    },

    exceptBnrScroll() {
      return !sessionStorage.getItem('lineBnrClose') ? 105 : 0;
    },
  },

  watch: {
    // 옵션 상세 영역에 뿌려줄 선택한 데이터 가공
    choicedOptionIdx() {
      this.choicedOptionData = {
        ...this.promotionData.plans.filter((plan) => plan.cp_idx === this.choicedOptionIdx)[0],

        choicedOptionCoupon:
          this.promotionData.couponPriceInfo.filter((plan) => plan.cpIdx === this.choicedOptionIdx)[0].discountMoney >
          10000
            ? `${this.promotionData.couponPriceInfo
                .filter((plan) => plan.cpIdx === this.choicedOptionIdx)[0]
                .discountMoney.toString()
                .charAt(0)}만원`
            : `${this.promotionData.couponPriceInfo
                .filter((plan) => plan.cpIdx === this.choicedOptionIdx)[0]
                .discountMoney.toString()
                .charAt(0)}천원`,

        monthlyPrice: this.promotionData.couponPriceInfo.filter((plan) => plan.cpIdx === this.choicedOptionIdx)[0]
          .moneyPerMonthAdaptedCoupon,

        tutorType:
          this.promotionData.plans.filter((plan) => plan.cp_idx === this.choicedOptionIdx)[0].t_type === 'C'
            ? 'GLOBAL'
            : 'NATIVE',

        tutorTypeLower:
          this.promotionData.plans.filter((plan) => plan.cp_idx === this.choicedOptionIdx)[0].t_type === 'C'
            ? 'Global'
            : 'Native',
      };
    },
  },
};
</script>

<style scoped src="../../assets/promotions.css"></style>
<style scoped src="../../assets/promotionsA.css"></style>
<style lang="scss" scoped>
.perfect-buds-allpage {
  overflow: hidden;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* 슬라이드 버튼 제거 */
.swiper-button-prev:after,
.swiper-container-rtl .swiper-button-next:after {
  display: none;
}

.swiper-button-next:after,
.swiper-container-rtl .swiper-button-prev:after {
  display: none;
}
.bold {
  font-weight: bold;
}
/* 프로모션 슬라이드(상단) */
.nov-third-allpage .slide .swiper-pagination {
  bottom: 0;
}

.nov-third-allpage .slide .swiper-pagination-bullet {
  background-color: #eaeaea;
  vertical-align: bottom;
  opacity: 1;
}

.nov-third-allpage .slide .swiper-pagination-bullet-active {
  background-color: #999;
}

/* 모바일 슬라이드 컨테이너 */
@media (max-width: 1199px) {
  .nov-third-allpage .slide .swiper-container {
    width: 100%;
    height: 273px;
  }
  .nov-third-allpage .slide .swiper-container .swiper-pagination .swiper-pagination-bullet {
    width: 6px;
    height: 6px;
  }
}

/* pc 슬라이드 이미지 */
.nov-third-allpage .slide__image--1 {
  width: 100%;
  height: 100%;
  background: url(//res.tutoring.co.kr/res/images/evt/202203/mar/pc_mar_thumb01.jpg) no-repeat 50%;
  background-size: contain;
}
.nov-third-allpage .slide__image--2 {
  width: 100%;
  height: 100%;
  background: url(//res.tutoring.co.kr/res/images/evt/202202/mar/pc_mar_thumb02.jpg) no-repeat 50%;
  background-size: contain;
}
.nov-third-allpage .slide__image--3 {
  width: 100%;
  height: 100%;
  background: url(//res.tutoring.co.kr/res/images/evt/202202/mar/pc_mar_thumb03.jpg) no-repeat 50%;
  background-size: contain;
}
.nov-third-allpage .slide__image--4 {
  width: 100%;
  height: 100%;
  background: url(//res.tutoring.co.kr/res/images/evt/202202/mar/pc_mar_thumb04.jpg) no-repeat 50%;
  background-size: contain;
}

/* 태블릿 슬라이드 이미지 */
@media (min-width: 768px) and (max-width: 1199px) {
  .nov-third-allpage .slide__image--1 {
    background: #202557 url(//res.tutoring.co.kr/res/images/evt/202203/mar/m_mar_thumb01.jpg) 50%;
    background-size: contain;
  }
  .nov-third-allpage .slide__image--2 {
    background: #202557 url(//res.tutoring.co.kr/res/images/evt/202202/mar/m_mar_thumb02.jpg) 50%;
    background-size: contain;
  }
  .nov-third-allpage .slide__image--3 {
    background: #202557 url(//res.tutoring.co.kr/res/images/evt/202202/mar/m_mar_thumb03.jpg) 50%;
    background-size: contain;
  }
  .nov-third-allpage .slide__image--4 {
    background: #202557 url(//res.tutoring.co.kr/res/images/evt/202202/mar/m_mar_thumb04.jpg) 50%;
    background-size: contain;
  }
}

/* 모바일 슬라이드 이미지 */
@media (max-width: 767px) {
  .nov-third-allpage .slide__image--1 {
    background: #202557 url(//res.tutoring.co.kr/res/images/evt/202203/mar/m_mar_thumb01.jpg) no-repeat 50%;
    background-size: cover;
  }
  .nov-third-allpage .slide__image--2 {
    background: #202557 url(//res.tutoring.co.kr/res/images/evt/202202/mar/m_mar_thumb02.jpg) no-repeat 50%;
    background-size: cover;
  }
  .nov-third-allpage .slide__image--3 {
    background: #202557 url(//res.tutoring.co.kr/res/images/evt/202202/mar/m_mar_thumb03.jpg) no-repeat 50%;
    background-size: cover;
  }
  .nov-third-allpage .slide__image--4 {
    background: #202557 url(//res.tutoring.co.kr/res/images/evt/202202/mar/m_mar_thumb04.jpg) no-repeat 50%;
    background-size: cover;
  }
}

.challenge-cta.is-info .challenge-cta__wrap {
  border-top: none;
}

/* 쿠폰 */
.nov-third-allpage .couponwrap {
  padding: 70px 0px 60px 0px;
  background: #ffd73f;
  text-align: center;
  color: #ffffff;
  max-width: 100%;
  overflow: hidden;
}
.nov-third-allpage .couponwrap::before {
  background: none;
}

.nov-third-allpage .couponwrap .top-desc {
  color: #1d2337;
  font-weight: 900;
}

.nov-third-allpage .couponwrap .bottom-desc {
  color: #1d2337;
}

.nov-third-allpage .couponwrap .coupon {
  margin: 0px auto;
  width: 300px;
  height: 150px;
}

.nov-third-allpage .couponwrap .coupon.coupon5000 {
  background: url(//res.tutoring.co.kr/res/images/evt/202202/mar/mar_coupon01_img.png) no-repeat 50%;
  background-size: contain;
}

.nov-third-allpage .couponwrap .coupon.coupon20000 {
  margin-top: 30px;
  margin-bottom: 30px;
  background: url(//res.tutoring.co.kr/res/images/evt/202202/mar/mar_coupon02_img.png) no-repeat 50%;
  background-size: contain;
}

.nov-third-allpage .couponwrap .coupon.coupon30000 {
  background: url(//res.tutoring.co.kr/res/images/evt/202202/mar/mar_coupon03_img.png) no-repeat 50%;
  background-size: contain;
}

.nov-third-allpage .couponwrap .coupon .coupon__button {
  position: absolute;
  right: 32px;
  top: 47px;
  width: 60px;
  height: 60px;
  background: url(//res.tutoring.co.kr/res/images/evt/202202/mar/coupon_button.png) no-repeat 50%;
  background-size: contain;
  cursor: pointer;
}

#coupons .coupon.is-download span {
  position: absolute;
  right: 32px;
  top: 47px;
  width: 60px;
  height: 60px;
  background: url(//res.tutoring.co.kr/res/images/evt/202107/coupon-downloaded.png) no-repeat 50%;
  background-size: contain;
}

/* 튜터의 타입이 궁금하신가요? */
.nov-third-allpage .atype-imgwrap {
  padding-bottom: 55px;
}

.nov-third-allpage__price {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #1d2337;
}

.nov-third-allpage__price-img {
  margin: 64px auto 77px auto;
  width: 320px;
  height: 405px;
  background: url(//res.tutoring.co.kr/res/images/evt/202203/mar/mar_price.png) no-repeat;
  background-size: contain;
}

/* 튜달이*/
.nov-third-allpage__tudal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #fef3d7;
}

.nov-third-allpage__tudal-text {
  display: inline-block;
  margin: 70px auto 25px auto;
  text-align: center;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: -1px;
  font-weight: 900;
  color: #1d2337;
}

.nov-third-allpage__tudal-img {
  margin: 0 auto 45px auto;
  width: 360px;
  height: 308px;
  background: url(//res.tutoring.co.kr/res/images/evt/202202/mar/img_study_tudal.gif) no-repeat;
  background-size: contain;
}

@media (min-width: 320px) and (max-width: 767px) {
  .nov-third-allpage__tudal-img {
    width: 100vw;
    height: calc(0.86 * 100vw);
  }

  .nov-third-allpage__price-img {
    width: 89vw;
    height: 112.5vw;
  }
}

/* 혜택 설명 통이미지 */
.nov-third-allpage__grade_desc {
  margin: 0px;
  padding: 95px 0px;
  width: 100vw;
  background: #1d2337;
}

.nov-third-allpage__grade_desc__img {
  height: 1828px;
  background: url(//res.tutoring.co.kr/res/images/evt/202202/mar/mar_img_01.png) no-repeat 50%;
  background-size: contain;
}

/* 반응형 적용 */
@media (min-width: 320px) and (max-width: 767px) {
  .nov-third-allpage__grade_desc__img {
    height: calc(5.08 * 100vw);
  }
}

/* 프로모션 타이머 타이틀 */
.nov-third-allpage .count-down {
  background: #fef3d7;
}

.nov-third-allpage .count-down .title-img {
  margin: 0 auto;
  width: 358px;
  height: 122px;
  background: url(//res.tutoring.co.kr/res/images/evt/202202/mar/mar_img_dday02.png) no-repeat 50%;
  background-size: contain;
}

.nov-third-allpage .count-down .timer {
  margin-top: 27px;
  font-size: 40px;
  font-style: normal;
  font-weight: 900;
  font-size: 40px;
  line-height: 40px;
}

.nov-third-allpage .count-down {
  padding: 70px 0px;
}

.nov-third-allpage .count-down .day {
  margin-top: 34px;
}

.nov-third-allpage .count-down .d-img {
  background: url(//res.tutoring.co.kr/res/images/evt/202202/mar/mar_img_dday00.png) no-repeat 50%;
  background-size: contain;
}

.nov-third-allpage .count-down .day .count-down__number {
  margin: 0 3px;
}

.nov-third-allpage .count-down .day .count-down__number {
  background: #1d2337;
  color: #fef3d7;
}

.nov-third-allpage .count-down .timer .count-down__number {
  display: inline-block;
  color: #1d2337;
  background: #fef3d7;
  margin: 0;
}

.nov-third-allpage .count-down .timer .dot::before,
.nov-third-allpage .count-down .timer .dot::after {
  background: #1d2337;
}

/* d-day */
.nov-third-allpage .count-down.is-date-end {
  background: #ffd73f;
}

.nov-third-allpage .count-down.is-date-end .title-img {
  background: url(//res.tutoring.co.kr/res/images/evt/202202/mar/mar_img_dday03.png) no-repeat 50%;
  background-size: contain;
  margin-bottom: 10px;
}
.nov-third-allpage .count-down.is-date-end .date-end {
  display: block;
  margin: 0 auto;
  width: 360px;
  height: 157px;
  background: url(//res.tutoring.co.kr/res/images/evt/202202/mar/mar_img_dday01.png) no-repeat 50%;
  background-size: contain;
}

/* 언택트 시대 튜터링으로 영어 해결! */
.nov-third-allpage .happy-allpass {
  padding: 70px 0px;
}
.nov-third-allpage .happy-allpass .happy-allpass__desc {
  margin-bottom: 0px;
}

/* 10분, 20분 바쁜 일상~  */
.nov-third-allpage .explan {
  height: 2388px;
  background: #5e04dc url(//res.tutoring.co.kr/res/images/evt/202105/oh/oh_365_mo01_img.jpg) no-repeat 50%;
  background-size: contain;
}

@media (min-width: 320px) and (max-width: 767px) {
  .nov-third-allpage .explan {
    height: calc(6.6 * 100vw);
  }
}

/* 일상 속 완벽한 영어 환경 */
.nov-third-allpage .purple-mobile .img {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 450px;
  background: url(//res.tutoring.co.kr/res/images/evt/202105/oh/oh_365_hand_img.jpg) no-repeat 50%;
  background-size: cover;
}

/* pride */
.pride {
  padding: 60px 0px;
  background: #000000;
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    color: #c89546;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    padding-bottom: 51px;

    &__no1 {
      display: block;
      font-size: 40px;
      line-height: 50px;
      padding-bottom: 8px;
    }
  }

  .img {
    width: 228px;
  }

  &__img {
    margin-top: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    li:first-child {
      margin-top: 50px;
      width: 176.47px;
      height: 48.83px;
      background: url(https://res.tutoring.co.kr/res/images/evt/com/award_2021_mo.png) no-repeat 50%;
      background-size: contain;
      opacity: 0.7;
    }

    li:nth-child(2) {
      margin: 20px auto 0;
      width: 176.47px;
      height: 49.83px;
      background: url(https://res.tutoring.co.kr/res/images/evt/com/award_2020_mo.png) no-repeat 50%;
      background-size: contain;
      opacity: 0.7;
    }

    li:nth-child(3) {
      margin: 20px auto 0;
      width: 176.47px;
      height: 50.83px;
      background: url(https://res.tutoring.co.kr/res/images/evt/com/award_2019_mo.png) no-repeat 50%;
      background-size: contain;
      opacity: 0.7;
    }
  }
}

/* 리뷰 박스 */
@media (max-width: 767px) {
  .nov-third-allpage .ontact-review .review__item {
    width: 320px;
  }
}

/* 후기, 유의사항 */
.nov-third-allpage .promotion-guide {
  padding-bottom: 40px;
}

@media (max-width: 767px) {
  .nov-third-allpage .promotion-certificate,
  .nov-third-allpage .promotion-guide,
  .nov-third-allpage .promotion-notice .inner {
    width: calc(100vw - 60px);
    max-width: 100%;
  }

  .nov-third-allpage .promotion-notice .heading__button {
    margin-left: 30px;
    padding: 0px;
    width: calc(100vw - 40px);
    max-width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .nov-third-allpage .promotion-notice .inner {
    width: calc(100vw - 100px);
    max-width: 100%;
  }
  .nov-third-allpage .promotion-notice .heading__button {
    margin-left: 50px;
    padding: 0px;
    width: calc(100vw - 100px);
    max-width: 100%;
  }
}

/* 구매하기 cta */
.nov-third-allpage .challenge-cta .challenge-cta__wrap {
  background: #1d2337;
}

.nov-third-allpage .challenge-cta .challenge-cta__wrap .date {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: #ff1212;
  color: #ffffff;
  padding: 0 17px;
}

/* 데스크톱 */
.nov-third-allpage .challenge-cta.is-info .cta__button {
  height: 48px;
  display: flex;
  background: #ffd73f;
  border-radius: 5px;
  color: #000000;
  align-items: center;
  justify-content: center;
  max-width: 240px;
  width: 100%;
  margin-left: auto;
  font-size: 21px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: -1.626947283744812px;
}

@media (max-width: 1199px) {
  .nov-third-allpage .challenge-cta.is-info .wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .nov-third-allpage .challenge-cta.is-info .wrapper .textwrap {
    width: 25%;
  }
  .nov-third-allpage .challenge-cta.is-info .wrapper .btnwrap {
    width: 75%;
  }
  .nov-third-allpage .challenge-cta.is-info .wrapper .btnwrap .cta__mobile {
    max-width: 100%;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .nov-third-allpage .challenge-cta.is-info .wrapper .textwrap {
    margin-right: 10px;
    width: 60%;
  }
  .nov-third-allpage .challenge-cta.is-info .cta__button {
    margin-top: 0;
    height: 38px;
    font-size: 14px;
    max-width: 100%;
    background: #ffd73f;
  }
}

/* bottom-up */
.nov-third-allpage .bottom_tab {
  display: flex;
  height: 38px;
  background: #5d04d9;
  border-radius: 5px;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: -1.1px;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-left: auto;
}

.nov-third-allpage .sheet .sheet__wrap .info {
  position: relative;
}

.nov-third-allpage .sheet .sheet__wrap .info .change-btn {
  padding: 0px 3px 0px 0px;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 52px;
  height: 26px;
  right: 0px;
  top: 72px;
  font-size: 12px;
  line-height: 12px;
  font-weight: bold;
  background: #ffffff;
}

.nov-third-allpage .sheet .sheet__wrap .info .change-btn .text::after {
  margin: 0px 1.5px 1px 0px;
  width: 5px;
  height: 5px;
}

.nov-third-allpage .promotion-notice .notice__item {
  padding-bottom: 100px;
}

/* 클릭 시 파란색 하이라이트 제거 */
.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}
</style>
