
import { Swiper, SwiperSlide } from 'swiper/vue';
import SwiperCore, { Navigation, Pagination, EffectFade, Autoplay } from 'swiper';
import { AxiosResponse } from 'axios';
import vClickOutside from 'click-outside-vue3';

import { storage, native, tracker } from '@/tools';
import api from '@/common/api';
import inappTools from '@/common/inappTools';
import tools from '@/common/tools';

import CountDown from './component/CountDown.vue';
import PlanSelectCard from './component/PlanSelectCard.vue';
import promotionMixins from '../../common/mixins/promotion';
import PopupToast from '../../components/PopupToast.vue';
import AppNav from '../../components/AppNav.vue';

import 'swiper/swiper-bundle.css';

SwiperCore.use([Navigation, Pagination, EffectFade, Autoplay]);

export default {
  name: 'Pr0301',
  components: {
    Swiper,
    SwiperSlide,
    PlanSelectCard,
    CountDown,
    PopupToast,
    AppNav,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  mixins: [promotionMixins],
  data() {
    return {
      isPopup: false,
      cuponPopupMessage: '쿠폰 다운로드 완료!',

      closedPromotion: false,

      planWithPrice: [{}],
      promotionData: { plans: [{ cp_idx: 0 }] },

      planGlobal: [{}],
      planNative: [{}],

      isPcTabOpen: false,
      isMobileTabOpen: false,
      isBottomTabOpen: false,
      isSampleClicked: false,

      // 사용자가 선택한 플랜의 idx
      choicedOptionIdx: 5715 as Number,

      // 사용자가 선택한 플랜 데이터 저장 _ 옵션 상세 부분 및 구매 이동에서 사용
      choicedOptionData: {
        origin_money: '',
        price: 0,
        discount: 0,
        minute: 0,
        lessonPerWeek: 0,
        lesson_cnt: 0,
        money: '',
        moneyPerMonth: 0,
        t_type: '',
        month_cnt: 0,
        choicedOptionCoupon: '',
        monthlyPrice: 0,
        tutorTypeLower: '',
      } as {
        // eslint-disable-next-line camelcase
        origin_money: string;
        price: number;
        discount: number;
        minute: number;
        lessonPerWeek: number;
        // eslint-disable-next-line camelcase
        lesson_cnt: number;
        money: string;
        moneyPerMonth: number;
        // eslint-disable-next-line camelcase
        t_type: string;
        // eslint-disable-next-line camelcase
        month_cnt: number;
        choicedOptionCoupon: string;
        monthlyPrice: number;
        tutorTypeLower: string;
      },

      // TODO : 프로모션 끝나는 기간 서버에서 받아와서 넣어야 함
      timestampEnd: new Date('20 Mar 2022 23:59:59').getTime(),
      timestampFinal: new Date('20 Mar 2022 00:00:00').getTime(),

      hasCouponIdx: [],

      bottomSheetUp: false,

      isMobileBottomSheetOpen: false,
    };
  },

  created() {
    this.getPromotionData();
  },
  // 스크롤 이벤트
  mounted() {
    this.onBottomSheetOn();
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.onBottomSheetOn);
  },

  methods: {
    clickTab(tabType: string): void {
      switch (tabType) {
        case 'pcTab':
          this.isPcTabOpen = !this.isPcTabOpen;
          break;

        case 'mobileTab':
          this.isMobileTabOpen = !this.isMobileTabOpen;
          if (inappTools.isInApp()) {
            tracker.recordUserAction('lastchance_payment_main');
          }
          break;
        case 'mobileTabChange':
          this.isMobileTabOpen = !this.isMobileTabOpen;
          if (inappTools.isInApp()) {
            tracker.recordUserAction('lastchance_change');
          }
          break;
        case 'mobileBottom':
          this.isBottomTabOpen = !this.isBottomTabOpen;
          break;

        default:
          break;
      }
    },
    closeTab(): void {
      this.isPcTabOpen = false;
      this.isMobileTabOpen = false;
      this.isBottomTabOpen = false;
      this.isMobileBottomSheetOpen = false;
    },
    closeTracker() {
      this.isMobileTabOpen = false;
      if (inappTools.isInApp()) {
        tracker.recordUserAction('lastchance_payment_close');
      }
    },
    btnTracker() {
      if (inappTools.isInApp()) {
        tracker.recordUserAction('lastchance_footer_close');
      }
      this.isMobileBottomSheetOpen = false;
    },

    openSample(): void {
      this.isSampleClicked = !this.isSampleClicked;
    },

    clickPopupButton() {
      this.isPopup = true;
      setTimeout(() => {
        this.isPopup = false;
      }, 2000);
    },
    async getPromotionData(): Promise<any> {
      // 로그인 되어 있을 경우 localStorage의 Key 사용
      // 로그인 되어 있지 않을 경우 하드코딩 된 Key 사용
      this.promotionData = await api
        .get('/app/etc/promotion', {
          channel: 8,
          promotionCode: 'MonthlyPro_202203',
        })
        .then((response: AxiosResponse) => response.data);

      this.choicedOptionIdx = this.promotionData.plans[0].cp_idx;

      this.choicedOptionData = {
        ...this.promotionData.plans[0],

        choicedOptionCoupon:
          this.promotionData.couponPriceInfo[0].discountMoney > 10000
            ? `${this.promotionData.couponPriceInfo[0].discountMoney.toString().charAt(0)}만원`
            : `${this.promotionData.couponPriceInfo[0].discountMoney.toString().charAt(0)}천원`,

        monthlyPrice: this.promotionData.couponPriceInfo[0].moneyPerMonthAdaptedCoupon,

        tutorType: this.promotionData.plans[0].t_type === 'C' ? 'GLOBAL' : 'NATIVE',
        tutorTypeLower: this.promotionData.plans[0].t_type === 'C' ? 'Global' : 'Native',
      };

      this.planWithPrice = this.promotionData.plans.map((v, i) => ({
        ...v,
        couponPrice: this.promotionData.couponPriceInfo[i].discountMoney / 10000,
      }));

      this.planGlobal = this.planWithPrice.filter((data) => data.t_type === 'C');
      this.planNative = this.planWithPrice.filter((data) => data.t_type === 'B');
    },

    async postCouponDownload(dcIdx: number, index: number): Promise<any> {
      if (inappTools.isInApp()) {
        tracker.recordUserAction(`lastchance_Coupon_${index + 1}`);
      }

      const userKey = localStorage.getItem('key');

      if (!userKey) {
        window.location.href = `https://${document.location.host}/home/login?goto=${document.location.pathname}`;
      } else {
        await api
          .post(`/app/users/coupon/discount/${dcIdx}`, {})
          .then((response) => {
            if (response.result === 2000) {
              this.cuponPopupMessage = '쿠폰 다운로드 완료!';
              this.hasCouponIdx.push(dcIdx);
              this.clickPopupButton();
            } else if (response.result === 1000) {
              this.cuponPopupMessage = response.message.replace(/\n|n/gi, '<br />');
              this.clickPopupButton();
            }
          })
          .catch((response) => {
            console.log(response);
          });
      }
    },
    clickBottomSheetTab() {
      if (inappTools.isInApp()) {
        tracker.recordUserAction('lastchance_footer');
      }
      if (this.isMobileBottomSheetOpen) {
        this.purchasePlan();
      }
      this.isMobileBottomSheetOpen = !this.isMobileBottomSheetOpen;
    },

    updateOptionIdx(Id) {
      this.choicedOptionIdx = Id;
      this.isMobileTabOpen = false;
    },

    pushToLogin() {
      if (!tools.isLoggedIn()) {
        window.location.href = '/home/login';
      }
    },

    purchasePlan() {
      const selectedPlan = this.promotionData.plans.filter((plan) => plan.cp_idx === this.choicedOptionIdx)[0];
      storage.session.set('goodsForOrder', selectedPlan);
      if (inappTools.isInApp()) {
        tracker.recordUserAction('lastchance_footer_payment');
        native.close();
      } else {
        this.pushToLogin();
        // eslint-disable-next-line no-useless-concat
        const url = `https://${document.location.host}/home/order?&ptype=Kr`;
        window.location.href = url;
      }
    },

    onBottomSheetOn() {
      window.addEventListener('scroll', () => {
        if (window.pageYOffset > 1926) {
          this.bottomSheetUp = true;
        } else {
          this.bottomSheetUp = false;
        }
      });
    },
  },

  computed: {
    hasCouponIdxArray() {
      return (dcIdx) => this.hasCouponIdx.includes(dcIdx);
    },

    sortNoticeText() {
      return this.promotionData.planGuide.map((data) => data.contents.map((e) => e.text.replace(/\n|n/gi, '<br />')));
    },
    appView() {
      return inappTools.isInApp();
    },
    isNotAppMargin() {
      if (!inappTools.isInApp()) {
        return '60px';
      }
      return '0px';
    },
    appPadding() {
      if (inappTools.isInApp()) {
        return '45px';
      }
      return '0px';
    },

    exceptBnrScroll() {
      return !sessionStorage.getItem('lineBnrClose') ? 105 : 0;
    },
  },

  watch: {
    // 옵션 상세 영역에 뿌려줄 선택한 데이터 가공
    choicedOptionIdx() {
      this.choicedOptionData = {
        ...this.promotionData.plans.filter((plan) => plan.cp_idx === this.choicedOptionIdx)[0],

        choicedOptionCoupon:
          this.promotionData.couponPriceInfo.filter((plan) => plan.cpIdx === this.choicedOptionIdx)[0].discountMoney >
          10000
            ? `${this.promotionData.couponPriceInfo
                .filter((plan) => plan.cpIdx === this.choicedOptionIdx)[0]
                .discountMoney.toString()
                .charAt(0)}만원`
            : `${this.promotionData.couponPriceInfo
                .filter((plan) => plan.cpIdx === this.choicedOptionIdx)[0]
                .discountMoney.toString()
                .charAt(0)}천원`,

        monthlyPrice: this.promotionData.couponPriceInfo.filter((plan) => plan.cpIdx === this.choicedOptionIdx)[0]
          .moneyPerMonthAdaptedCoupon,

        tutorType:
          this.promotionData.plans.filter((plan) => plan.cp_idx === this.choicedOptionIdx)[0].t_type === 'C'
            ? 'GLOBAL'
            : 'NATIVE',

        tutorTypeLower:
          this.promotionData.plans.filter((plan) => plan.cp_idx === this.choicedOptionIdx)[0].t_type === 'C'
            ? 'Global'
            : 'Native',
      };
    },
  },
};
